import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import store from "./store";
import api from "./axios";
import { setupInterceptors } from "./axiosInterceptors";

import "./i18next";


const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Suspense fallback={<div>isLoading...</div>}>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        ref={notistackRef}
        action={(key) => (
          <IconButton size='small' onClick={onClickDismiss(key)} style={{color: "white"}}><CloseIcon/></IconButton>
        )}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// Initialize axios interceptors
setupInterceptors(api);
