import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import lazy from "./features/utils/LazyWithRetry";
import Home from "./pages/Home";
import { auth } from "./features/authSlice";
import { selectProfile } from "./features/profileSlice";

const ShareLifeLearnings = lazy(() => import("./pages/ShareLifeLearnings"));
const SearchLifeLearnings = lazy(() => import("./pages/SearchLifeLearnings"));
const ProfilePage = lazy(() => import("./pages/Profile"));
const MyLifeLearnings = lazy(() => import("./pages/MyLifeLearnings"));
const SavedLifeLearnings = lazy(() => import("./pages/SavedLifeLearnings"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const MinibookView = lazy(() => import("./pages/MinibookView"));
const ContributeView = lazy(() => import("./pages/ContributePage"));
const PasswordChangeFromEmailView = lazy(() =>
  import("./pages/PasswordResetFormPage"),
);
const UnsubscribeView = lazy(() => import("./pages/EmailUnsubscribePage"));
const EmailChangeConfirmView = lazy(() =>
  import("./pages/EmailChangeConfirmPage"),
);
const CreateMinibook = lazy(() => import("./pages/CreateMinibook"));
const EditMinibook = lazy(() => import("./pages/EditMinibook"));
const CreateMinibookSuccess = lazy(() =>
  import("./pages/CreateMinibookSuccess"),
);
const EmailVerifyCallbackView = lazy(() =>
  import("./pages/EmailVerifyCallbackPage"),
);
const OnboardingCallbackView = lazy(() => import("./pages/OnboardingCallback"));
const LoginRequiredView = lazy(() => import("./pages/LoginRequired"));
const ErrorViewPage = lazy(() => import("./pages/ErrorPage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));

export default function MyRoutes() {
  const { isAuthenticated } = useSelector(auth);
  const { profile } = useSelector(selectProfile);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/profile"
            element={isAuthenticated ? <ProfilePage /> : <LoginRequiredView />}
          />
          <Route
            path="/share"
            element={
              isAuthenticated ? <ShareLifeLearnings /> : <LoginRequiredView />
            }
          />
          <Route path="/search" element={<SearchLifeLearnings />} />
          <Route
            path="/my_learnings"
            element={
              isAuthenticated ? <MyLifeLearnings /> : <LoginRequiredView />
            }
          />
          <Route
            path="/saved_learnings"
            element={
              isAuthenticated ? <SavedLifeLearnings /> : <LoginRequiredView />
            }
          />
          <Route
            path="/myaccount"
            element={isAuthenticated ? <MyAccount /> : <LoginRequiredView />}
          />
          <Route
            path="/minibook/:slug/contribute"
            element={
              isAuthenticated ? <ContributeView /> : <LoginRequiredView />
            }
          />
          <Route
            path="/minibook/:slug"
            element={isAuthenticated ? <MinibookView /> : <LoginRequiredView />}
          />
          <Route
            path="/account/password_reset/:uid/:token"
            element={<PasswordChangeFromEmailView />}
          />
          <Route
            path="/account/email_unsubscribe/:uid/:token"
            element={<UnsubscribeView />}
          />
          <Route
            path="/account/change_email/:uid/:token/:enc_email"
            element={<EmailChangeConfirmView />}
          />
          <Route
            path="/create_book"
            element={
              isAuthenticated ? profile.user_info != undefined ? <CreateMinibook />: <Navigate to="/my_learnings" /> : <LoginRequiredView />
            }
          />
          <Route
            path="/edit_book"
            element={isAuthenticated ? profile.user_info != undefined ? <EditMinibook />: <Navigate to="/my_learnings" /> : <LoginRequiredView />}
          />
          <Route
            path="/book_published"
            element={
              isAuthenticated ? (
                <CreateMinibookSuccess />
              ) : (
                <LoginRequiredView />
              )
            }
          />
          <Route
            path="/account/verify_email/:uid/:token"
            element={<EmailVerifyCallbackView />}
          />
          <Route
            path="/account/stripe/authorize"
            element={<OnboardingCallbackView />}
          />
          <Route path="/error" element={<ErrorViewPage />} />
          <Route
            path="/lile-admin"
            element={isAuthenticated ? <AdminPage /> : <LoginRequiredView />}
          />
          <Route path="/" element={<Home />} />
        </Routes>
      </Suspense>
    </>
  );
}
