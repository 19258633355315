import { configureStore } from "@reduxjs/toolkit";
import lifeLearningsReducer from "../features/lifeLearningsSlice";
import myLifeLearningsReducer from "../features/myLifeLearningsSlice";
import miniBooksReducer from "../features/miniBooksSlice";
import myMinibooksReducer from "../features/myMiniBooksSlice";
import miniBookReducer from "../features/miniBookSlice";
import categoriesReducer from "../features/categoriesSlice";
import savedLearningsReducer from "../features/savedLearningsSlice";

import authReducer from "../features/authSlice";
import resetPasswordReducer from "../features/resetPasswordSlice";
import profileReducer from "../features/profileSlice";
import searchReducer from "../features/searchSlice";
import writerProfileReducer from "../features/writerProfileSlice";
import relatedLifeLearningReducer from "../features/relatedLifeLearningSlice";
import TTSReducer from "../features/TTSSlice";
import contributeReducer from "../features/contributeSlice";
import myAccountReducer from "../features/myAccountSlice";
import minibookDraftReducer from "../features/minibookDraftSlice";
import modalReducer from "../features/modalSlice";
import reportsReducer from "../features/reportSlice";
import bannerReducer from "../features/adBannerSlice";
import charitiesReducer from "../features/charitiesSlice";
import ratingReducer from "../features/ratingSlice";
import { combineReducers } from "@reduxjs/toolkit";

const reducer = combineReducers({
  lifeLearnings: lifeLearningsReducer,
  minibooks: miniBooksReducer,
  myLifeLearnings: myLifeLearningsReducer,
  myMinibooks: myMinibooksReducer,
  miniBook: miniBookReducer,
  categories: categoriesReducer,
  savedLearnings: savedLearningsReducer,
  auth: authReducer,
  passwordReset: resetPasswordReducer,
  profile: profileReducer,
  search: searchReducer,
  writerProfile: writerProfileReducer,
  relatedLifeLearning: relatedLifeLearningReducer,
  textToSpeech: TTSReducer,
  myaccount: myAccountReducer,
  contribute: contributeReducer,
  minibookDraft: minibookDraftReducer,
  showModal: modalReducer,
  reports: reportsReducer,
  banners: bannerReducer,
  charities: charitiesReducer,
  score: ratingReducer,
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === 'development',
});

export default store;
