import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Container, Modal } from "react-bootstrap";
import Carousel from "../components/homepage/Carousel";
import LifeLearningsFeed from "../components/homepage/TopRatedLifeLearnings";
import TopRatedMiniBooks from "../components/homepage/TopRatedMiniBooks";
import Pictures from "../components/homepage/Pictures";
import OurMission from "../components/homepage/OurMission";
//import GoogleAds from "../components/homepage/GoogleAds";
import { auth, setNextURL } from "../features/authSlice";
import { setLoginOrCreateAccount } from "../features/modalSlice";
import { selectBanners, fetchAffiliateLinks } from "../features/adBannerSlice";
import { useTranslation } from "react-i18next";
import Advertising from "../components/utils/Advertising";
import NeedHelpModal from "../components/modals/NeedHelpModal";
import MetaTags from 'react-meta-tags';

const Home = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();

  const { isAuthenticated } = useSelector(auth);
  const { affiliateLinks } = useSelector(selectBanners);

  const [needHelpShow, setNeedHelpShow] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const openModal = () => {
    dispatch(setNextURL("/share"));
    dispatch(setLoginOrCreateAccount(true));
  };

  useEffect(() => {
    dispatch(fetchAffiliateLinks());
  }, [dispatch]);


  useEffect(() => {
    if (query.get("modal")=="need-help"&&modalOpened==false)
      setNeedHelpShow(true);
      setModalOpened(true);
  }, [query, setNeedHelpShow, setModalOpened, modalOpened])

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  return (
    <div className="wrapper">
      <MetaTags>
        <title>LifeLearnings - World&apos;s insights on Life</title>
        <meta name="description" content="We believe in the power of personal growth. LifeLearnings values the human experience and brings invaluable guidance to readers." />
        <meta property="og:home" content="homepage" />
      </MetaTags>
      <div className="home-page">
        <Carousel />
        <Container>
          <div>
            <LifeLearningsFeed />
            <div className="share-learning-btn-wrapper">
              {isAuthenticated ? (
                <Link to="/share">
                  <button className="button-black btn-share-learning">
                    {t("home>Share Life Learnings")}
                  </button>
                </Link>
              ) : (
                <button
                  className="button-black btn-share-learning "
                  onClick={() => openModal()}
                >
                  {t("home>Share Life Learnings")}
                </button>
              )}

              <Link to="/search">
                <button className="button-black btn-search-learning">
                  {t("home>Search Life Learning")}
                </button>
              </Link>
            </div>
            <TopRatedMiniBooks />
            <Pictures />
              {Advertising('frontpage', affiliateLinks)}
            <div className="hidden">
              <p>{t("home>Top Charities...")}</p>
            </div>
          </div>
        </Container>
        <OurMission />
        <Modal
          size="xl"
          show={needHelpShow}
          onHide={() => setNeedHelpShow(false)}
          animation={false}
          dialogClassName="modal-100w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <NeedHelpModal></NeedHelpModal>
        </Modal>
      </div>
    </div>
  );
};

export default Home;