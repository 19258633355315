import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

/* global __webpack_hash__ */
const hash = __webpack_hash__;
const languages = ["en", "fi"];
export const fallBackLng = "en";
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: fallBackLng,
    debug: false,
    supportedLngs: languages,

    // Namespace settings
    ns: ["translation"],
    defaultNS: "translation",

    // Fallbacks to key when translations are unimplemented
    returnEmptyString: false,

    // Replaces default . and : separators
    nsSeparator: "|",
    keySeparator: ">",

    backend: {
      // for all available options read the backend's repository readme file

      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      //
      // If allowMultiLoading is false, lngs and namespaces will have only one element each,
      // If allowMultiLoading is true, lngs and namespaces can have multiple elements
      loadPath: '/locales/{{lng}}/{{ns}}.json',

      // path to post missing resources, or a function
      // function(lng, namespace) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      addPath: '/locales/add/{{lng}}/{{ns}}',

      // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
      queryStringParams: { hash: hash  },

      requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        cache: 'no-cache'
      }
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

  });

export default i18n;
